<template>
    <div>
        <a-modal
            v-if="settings.options"
            :visible="(opportunityModal.type === 'Opportunity' || opportunityModal.type == 'Convert Opportunity' || opportunityModal.type == 'Lead Opportunity') && opportunityModal.visible"
            @cancel="onClose"
            @ok="onClose"
            :footer="null"
            :bodyStyle="{padding: '30px'}"
            width="800px"
            centered
            >
            <BHLoading :show="loading"/>
			<template #title>
				<h5>{{opportunityModal.type === 'Opportunity' || opportunityModal.type == 'Lead Opportunity' ? 'Add New Opportunity':`Convert ${defaultName} to an Opportunity`}}</h5>
			</template>

            <a-form-model ref="ruleForm" :model="opportunity" :rules="rules">
				<a-row :gutter="16">
					<a-col :span="12" :key="defaultName">
						<a-form-model-item label="Primary Contact" prop="contact">
							<template v-if="leadOpportunity == ''">
								<searchContact v-if="opportunityModal.type === 'Opportunity' && opportunityModal.visible" @updatecontact="updatePerson" />
							</template>
							<template v-else>
								<a-input size="large" :default-value="defaultName" disabled />
							</template>
						</a-form-model-item>
					</a-col>
					<a-col :span="12">
						<a-form-model-item label="Deal Stage" prop="stage">
							<a-select @change="changeStage" v-model="opportunity.stage" style="width: 100%;" size="large">
                                <div slot="dropdownRender" slot-scope="menu">
                                    <v-nodes :vnodes="menu" />
                                    <a-divider style="margin: 4px 0;" />
                                    <div
                                        style="padding: 8px; cursor: pointer; color:var(--orange)"
                                        @mousedown="e => e.preventDefault()"
                                        @click="addNewStage"
                                    >
                                        <i style="font-size:15px;" class="fe fe-edit-2 mr-2" /> Add New Deal Stage
                                    </div>
                                </div>
                                <template v-if="settings.options && settings.options.stages && settings.options.stages.list">
                                    <template v-for="(stage,stageI) in settings.options.stages.list">
                                        <a-select-option v-if="stage && stage.id != 'transaction'" :key="stage+stageI" :value="stage.id">
                                            {{stage.name}}
                                        </a-select-option>
                                    </template>
                                </template>
                            </a-select>
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="16">
					<a-col :span="12">
						<a-form-model-item prop="closeDate">
							<template #label>
								<a-tooltip overlayClassName="change-tooltip-color">
	                                <template slot="title">
	                                    Select the estimated close date of the opportunity, not when the home will close
	                                </template>
									Estimated Opportunity Close Date
	                                <a-icon type="question-circle" style="font-size: 14px; color: black; margin-left: 2px;"/>
	                            </a-tooltip>
							</template>
							<a-date-picker v-model="opportunity.closeDate" size="large" placeholder="dd/mm/yyyy" style="width: 100%;" />
						</a-form-model-item>
					</a-col>

					<a-col :span="12">
						<a-form-model-item label="Deal Source" prop="dealSource">
                            <a-select v-model="opportunity.dealSource" style="width: 100%;" size="large">
                                <div slot="dropdownRender" slot-scope="menu">
                                    <v-nodes :vnodes="menu" />
                                    <a-divider style="margin: 4px 0;" />
                                    <div
                                        style="padding: 8px; cursor: pointer; color:var(--orange)"
                                        @mousedown="e => e.preventDefault()"
                                        @click="addNewSource"
                                    >
                                        <i style="font-size:15px;" class="fe fe-edit-2 mr-2" /> Add New Deal Source
                                    </div>
                                </div>
                                <a-select-option v-for="(source,sourceI) in dealSources" :key="source+sourceI" :value="source.id ? source.id : source.value">
                                    {{source.name}}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
					</a-col>
				</a-row>

				<a-row :gutter="16">
					<a-col :span="12">
                        <a-form-model-item :label="instance.productType == 'lowrise' ? 'Lot' : 'Unit'" prop="product">
                            <a-cascader v-if="instance.productType == 'lowrise' && opportunityModal.visible" :options="products" size="large" @change="selectLot" placeholder="Select a lot" />
                            <a-cascader v-if="instance.productType == 'highrise' && opportunityModal.visible" :options="products" size="large" @change="selectProduct" placeholder="Select a unit" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="Value" prop="dealValue">
							<a-input-number style="width:100%" v-model="opportunity.dealValue" :formatter="price => `$ ${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0" placeholder="Add deal value" size="large" />
						</a-form-model-item>
                    </a-col>
                </a-row>

                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-model-item label="Visibility" prop="visibility">
                            <a-select @change="changeVisibility" v-model="opportunity.visibility" style="width: 100%;" size="large">
                                <a-select-option value="everyone">
                                    Everyone
                                </a-select-option>
                                <a-select-option value="owners">
                                    Only Sales Owner(s)
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="Sales Owner(s)" prop="owners">
                            <a-select
                                v-model="opportunity.owners"
                                mode="multiple"
                                placeholder="Add owner"
                                size="large"
                                :not-found-content="null"
                                :filter-option="filterOption"
                                @search="searchAssign">
                                <a-select-option v-for="user in userSource" :key="user.id" :value="user.id">
                                    {{ user.name }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>

                <a-row>
                    <a-col :span="24">
						<a-form-model-item prop="interested">
							<template #label>
								<a-tooltip overlayClassName="change-tooltip-color">
	                                <template slot="title">
										Add description of opportunity
	                                </template>
									Interested In
	                                <a-icon type="question-circle" style="font-size: 14px; color: black; margin-left: 2px;"/>
	                            </a-tooltip>
							</template>
							<a-textarea
	                            v-model="opportunity.interested"
	                            :auto-size="{ minRows: 4, maxRows: 6 }"
	                            placeholder="Add description of opportunity"
                            />
						</a-form-model-item>
                    </a-col>
                </a-row>
				<a-alert v-if="!opportunity.product" type="info" show-icon>
					<template #message>
						Agents should determine the {{instance.productType == 'lowrise' ? 'Lot and Elevation' : 'Floor and Unit' }} before proceeding with the transaction, as this information impacts pipeline reporting.
					</template>
				</a-alert>
            </a-form-model>

            <div style="text-align: right;">
                <a-button @click="onClose" class="mt-3 mr-3 cancel-button" size="large" style="width: 125px;">CANCEL</a-button>
                <a-button @click="onSubmit" style="width: 125px;" size="large" type="primary">CREATE</a-button>
            </div>
        </a-modal>
        <a-modal @cancel="closeNotification" :visible="notiVisible" :centered="true" :footer="null">
            <h5>{{`${defaultName} has been successfully converted!`}}</h5>
            <div>{{`${defaultName} - has been added to your opportunity pipeline.`}}</div>
            <div class="mt-3 dF jE">
                <a-button @click="returnLeads" class="mr-3" style="color:var(--orange); border-color:var(--orange)">RETURN TO LEADS</a-button>
                <a-button @click="viewOpportunities" type="primary">VIEW OPPORTUNITIES</a-button>
            </div>
        </a-modal>
    </div>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'
import searchContact from '@/components/common/searchContact'
import moment from 'moment'

export default {
    components:{
        BHLoading,searchContact,
        VNodes: {
        functional: true,
        render: (h, ctx) => ctx.props.vnodes,
        },
    },
    data() {
        return {
            closeDeal:false,
            opportunity:{
                name:'',
                contact:'',
                closeDate:'',
                company:'',
                owners:[],
                stage:'',
                dealValue:'',
                dealSource:'nosource',
                probability:20,
                visibility:'everyone',
                interested:'',
                floor:'',
                product:''
            },
            defaultName:'',
            userSource:[],
            loading:false,
            notiVisible:false,

            rules: {
                contact: [
                    { required: true, message: 'Please input a contact', trigger: 'blur' },
                ],
				stage: [
                    { required: true, message: 'Please select a stage', trigger: 'blur' },
                ],
                closeDate: [
                    { required: true, message: 'Please input a close date' },
                ],
                owners:[
                    { required: true, message: 'Please select one', trigger: 'blur' },
                ]
            },
        }
    },
    watch:{
        visible:{
            handler(val) {
                if (val && this.opportunityModal.type && (this.opportunityModal.type == 'Opportunity' || this.opportunityModal.type == 'Convert Opportunity' || this.opportunityModal.type == 'Lead Opportunity')) {
                    let obj = {}
                    obj = this.owner
                    obj.name = `${this.owner.firstName} ${this.owner.lastName}`
                    if (this.leadOpportunity != '') {
                        if (this.$store.state.contacts.allContacts[this.leadOpportunity] && this.$store.state.contacts.allContacts && this.$store.state.contacts.allContacts[this.leadOpportunity].owners) {
                            this.$store.state.contacts.allContacts[this.leadOpportunity].owners.forEach(user => {
                                let id = user
                                if (id.id) id = id.id
                                let find = this.users.find(x => x.id == id)
                                if (find) {
                                    let obj = {}
                                    obj = find
                                    obj.name = `${find.firstName} ${find.lastName}`
                                    this.userSource.push(obj)
                                    this.opportunity.owners.push(find.id)
                                }
                            })
                        } else if (this.$store.state.contacts.externalContacts[this.leadOpportunity] && this.$store.state.contacts.externalContacts && this.$store.state.contacts.externalContacts[this.leadOpportunity].owners) {
                            this.$store.state.contacts.externalContacts[this.leadOpportunity].owners.forEach(user => {
                                let id = user
                                if (id.id) id = id.id
                                let find = this.users.find(x => x.id == id)
                                if (find) {
                                    let obj = {}
                                    obj = find
                                    obj.name = `${find.firstName} ${find.lastName}`
                                    this.userSource.push(obj)
                                    this.opportunity.owners.push(find.id)
                                }
                            })
                        }
                    }
                    if (!this.userSource.map(x => x = x.id).includes(this.owner.id)) {
                        this.userSource.push(obj)
                        this.opportunity.owners.push(this.owner.id)
                    }
                    this.opportunity.stage = ''
                    this.opportunity.dealSource = 'nosource'
                } else if (!val) {
                    this.opportunity.owners = []
                    this.userSource = []
                    this.$store.commit('CLEAR_LEAD_ACTION')
                    this.opportunity.contact = ''
                    this.defaultName = ''
                }
            }
        },
        leadOpportunity:{
            async handler(val) {
                if (val != '' && (this.opportunityModal.type == 'Opportunity' || this.opportunityModal.type == 'Convert Opportunity' || this.opportunityModal.type == 'Lead Opportunity')) {
                    this.opportunity.contact = val
                    if (this.$store.state.contacts.allContacts[val] && this.$store.state.contacts.allContacts) {
                        let find = this.$store.state.contacts.allContacts[val]
                        if(find && find.firstName && find.lastName && find.firstName.trim() && find.lastName.trim()) this.defaultName = `${find.firstName} ${find.lastName}`
                        else if (find) this.defaultName = `${find.email}`
                    } else if (this.$store.state.contacts.externalContacts && this.$store.state.contacts.externalContacts[val]) {
                        let find = this.$store.state.contacts.externalContacts[val]
                        if(find && find.firstName && find.lastName && find.firstName.trim() && find.lastName.trim()) this.defaultName = `${find.firstName} ${find.lastName}`
                        else if (find) this.defaultName = `${find.email}`
                    } else if (this.leadOpportunity) {
						this.loading = true;
						await this.$api.get(`/contacts/:instance/${this.leadOpportunity}`).then( ({data}) => {
                            this.$store.commit('ADD_EXTERNAL_CONTACT', data)
							this.defaultName = data.firstName + ' ' + data.lastName
                            this.loading = false
                        }).catch(err => {
							this.loading = false
						})
					}
                }
            }
        }
    },
    computed:{
        products(){
            let products = JSON.parse(JSON.stringify(this.origProducts))
            if (this.instance.productType == 'lowrise'){
                let elevations = JSON.parse(JSON.stringify(this.elevations))
                products.sort((a,b) => {
                    return a.name - b.name
                })

                let newProducts = []
                products.forEach(x => {
                    let obj = {
                        value:'',
                        label:'',
                        children:[]
                    }
                    obj.value = x.id
                    obj.label = 'Lot ' + x.name + `${x.block ? ` (Block ${x.block})` : ''}`

                    x.units.forEach(y => {
                        let foundUnit = elevations.find(z => {
                            if (z && z.hasOwnProperty('id')) return z.id == y
                            return false
                        })
                        if (foundUnit){
                            let objChild = {
                                value:'',
                                label:'',
                                disabled:false
                            }
                            objChild.value = foundUnit.id
                            objChild.label = foundUnit.name
                            obj.children.push(objChild)
                        }
                    })
                    newProducts.push(obj)
                })
                return newProducts
            } else if (this.instance.productType == 'highrise'){
                let newProducts = []
                products.forEach(x => {
                    let obj = {
                        value:'',
                        label:'',
                        children:[]
                    }
                    obj.value = x.id
                    obj.label = x.name

                    x.units.forEach(y => {
                        let objChild = {
                            value:'',
                            label:'',
                            disabled:false
                        }
                        if (y.salesStatus != 'available') objChild.disabled = true
                        objChild.value = y.id
                        if (y.name == y.unitNumber) objChild.label = 'Unit ' + y.unitNumber
                        else objChild.label = y.name + ` (Unit ${y.unitNumber})`
                        obj.children.push(objChild)
                    })
                    newProducts.push(obj)
                })
                return newProducts
            }
        },
        elevations(){
            if (this.instance.productType == 'lowrise'){
                let elevations = JSON.parse(JSON.stringify(this.origElevations))
                elevations = elevations.map(x => {
                    if (x.unitGroup && x.unitGroup.hasOwnProperty('name')){
                        return {
                            id:x.id,
                            name:`${x.unitGroup.name} ${x.name}`
                        }
                    }
                })
                return elevations
            }
        },
        origElevations(){
            return this.$store.state.elevations
        },
        origProducts(){
            return this.$store.state.products
        },
        allContacts() {
            return this.$store.state.contacts.allContacts || {}
        },
        externalContacts() {
            return this.$store.state.contacts.externalContacts || {}
        },
        leadOpportunity() {
            return this.$store.state.contacts.leadAction
        },
        dealSources() {
            if (this.settings && this.settings.options && this.settings.options.dealSources && this.settings.options.dealSources.length > 0) {
                return this.settings.options.dealSources
            } else {
                return [
                    {name:'No Source', id:'nosource'},
                    {name:'Email', id:'email'},
                    {name:'Cold Call', id:'coldcall'},
                    {name:'Advertising', id:'advertising'}
                ]
            }
        },
        visible() {
            return this.$store.state.addNew.visible
        },
        owner() {
            return this.$store.state.user.user
        },
        opportunityModal(){
            return this.$store.state.addNew
        },
        settings(){
            return this.$store.state.contacts.allSettings.app || {}
        },
        currentPage:{
            get(){
                return this.$store.state.appData.currentPage
            },
            set(val){
                this.$store.commit('SET_CONTACTS_PAGE', val)
            }
        },
        instance(){
            return this.$store.state.instance
        },
        users(){
            return this.$store.state.contacts.users
        },
        loadingPage:{
            get(){
                return this.$store.state.appData.loadingPage
            },
            set(val){
                this.$store.commit('LOAD_PAGE',val)
            }
        }
    },
    methods:{
        selectLot(e){
            this.opportunity.floor = e[0]
            this.opportunity.product = e[1]
            let price = 0
            this.origProducts.forEach(x => {
                if (x.id == this.opportunity.floor) {
                    let premiums = Object.values(x.other.premiums)
                    premiums.forEach(y => {
                        if (y.hasOwnProperty('active') && y.active) {
                            price += y.price
                        }
                    })
                }
            })
            let foundElevation = this.origElevations.find(x => {
                if (x && x.hasOwnProperty('id')) return x.id == this.opportunity.product
                return false
            })
            if (foundElevation){
                if (foundElevation.packages && foundElevation.packages.length){
                    price += foundElevation.packages[0].price
                }
            }
            this.opportunity.dealValue = price
        },
        selectProduct(e){
            this.opportunity.floor = e[0]
            this.opportunity.product = e[1]
            this.origProducts.forEach(x => {
                if (x.id == this.opportunity.floor) {
                    x.units.forEach(y => {
                        if (y.id == this.opportunity.product) {
                            this.opportunity.dealValue = y.packages[0].price
                        }
                    })
                }
            })
        },
        changeStage(e) {
            if (this.settings.options && this.settings.options.stages && this.settings.options.stages.list) {
                let find = this.settings.options.stages.list.find(x => x && x.id == e)
                if (find && find.probability) this.opportunity.probability = find.probability
                else if (find) this.opportunity.probability = 20
            }
        },
        addNewStage() {
            this.$store.commit('OPEN_LEAD_STATUS', {type:'dealStage'})
        },
        addNewSource() {
            this.$store.commit('OPEN_LEAD_STATUS', {type:'dealSource'})
        },
        returnLeads() {
            if (this.$route.path && !this.$route.path.includes('/leads')) {
                this.$router.push('/leads')

            }
            this.closeNotification()
        },
        viewOpportunities() {
            this.$router.push('/opportunities/sales')
            this.closeNotification()
        },
        changeVisibility(e) {
            if (e == 'owners') {
                let ids = this.userSource.map(x => x = x.id)
                this.users.forEach(user => {
                    user.name = `${user.firstName} ${user.lastName}`
                    if (!ids.includes(user.id)) this.userSource.push(user)
                })
            } else if (e == 'everyone') {
                let obj = {}
                obj = this.owner
                obj.name = `${this.owner.firstName} ${this.owner.lastName}`
                this.userSource = [obj]
            }
        },
        moment,
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        searchAssign(input){
            if(input && input.length>1) {
                this.users.forEach(x => {
                    x.name = x.firstName+ ' ' + x.lastName
                })
                this.userSource = this.users.filter(({name}) => {
                    name = name.toLowerCase()
                    input = input.toLowerCase()
                    return name.includes(input)
                })
            } else {
                return this.userSource = []
            }
        },
        updatePerson(contact){
            this.opportunity.contact = contact
        },
        closeNotification() {
            this.notiVisible = false
        },
        onClose(){
            let type = this.opportunityModal.type
			if(this.$refs['ruleForm']){
				this.$refs['ruleForm'].resetFields();
			}
            this.$store.commit('CLOSE_ADD_NEW')
            this.opportunity = {
                name:'',
                contact:'',
                closeDate:'',
                company:'',
                owners:[],
                stage:'',
                dealValue:'',
                dealSource:'nosource',
                probability:20,
                visibility:'everyone',
                interested:'',
                floor:'',
                product:''
            },
            this.userSource = []
            if ((type == 'Convert Opportunity' || type == 'Lead Opportunity') && this.closeDeal) {
                this.notiVisible = true
            }
            this.closeDeal = false
        },
        onSubmit(){

            this.$refs.ruleForm.validate(async valid => {

                if (valid) {
                    this.loading = true

                    let sendObj = JSON.parse(JSON.stringify(this.opportunity))
					const findFloor = this.origProducts.find(x => x.id == sendObj.floor);
					if (this.instance.productType == 'lowrise') {
						if (findFloor) {
							const findProduct = this.origElevations.find(x => x.id == sendObj.product);
							sendObj.name = findProduct
								? `Lot ${findFloor.name}${findFloor.block ? ` (Block ${findFloor.block})` : ''} ${findProduct.unitGroup?.name ? '/ ' + findProduct.unitGroup.name : ''} ${findProduct.name}`
								: 'Lot & Elevation Not Selected TBD';
						} else {
							sendObj.name = 'Lot & Elevation Not Selected TBD';
						}
					} else if (this.instance.productType == 'highrise') {
						if (findFloor) {
							const findProduct = findFloor.units.find(x => x.id == sendObj.product);
							sendObj.name = findProduct
								? (findProduct.name == findProduct.unitNumber ? 'Unit ' + findProduct.unitNumber : findProduct.name + ` (Unit ${findProduct.unitNumber})`)
								: 'Floor &Unit Not Selected TBD';
						} else {
							sendObj.name = 'Floor & Unit Not Selected TBD';
						}
					}

                    sendObj.closeDate = parseInt(this.moment(sendObj.closeDate).format('X'))*1000

                    this.$api.post(`/opportunities/:instance`,sendObj).then( ({data}) => {
                        this.$store.commit('ADD_OPPORTUNITY', data)
                        this.loading = false
                        this.closeDeal = true
                        this.onClose()
                    }).catch(err => {
						this.loading = false
						this.$toastError(err, 'Error while creating opportunity. Please try again')
					})
                }
            });
        },
    },
}
</script>

<style lang="scss">
    .popoverStyle .ant-popover-inner-content {
        padding: 0;
        background-color: white;
        border-radius: 20px;
    }
    .change-tooltip-color .ant-tooltip-content .ant-tooltip-inner{
        background-color:#3f3356;
    }
</style>

<style lang="scss" scoped>
    .popoverContent{
        height: 35px;
        width: 150px;
        line-height: 35px;
        padding-left: 10px;
    }
    .popoverContent:hover{
        background-color: var(--off-white-light);
        cursor: pointer;
    }
</style>
