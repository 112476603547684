<template>
	<div style="z-index: 1000;">
		<a-modal title="Add New Task" :visible="contactModal.type === 'Task' && contactModal.visible"
			@cancel="closeTaskModal" :footer="null" centered :confirmLoading="loading" width="1000px">
			<BHLoading :show="loading" />
			<div>
				<a-form-model ref="newTask" :model="newTask">
					<a-row :gutter="16">
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Task Title" prop="title"
								:rules="$req('Please enter the task title')">
								<a-input v-model="newTask.title" placeholder="Task Title" size="large">
								</a-input>
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Task Type" prop="type" :rules="$req('Please select task Type')">
								<a-select v-model="newTask.type" size="large" show-search
									:filter-option="$filterOption">
									<div slot="dropdownRender" slot-scope="menu">
										<v-nodes :vnodes="menu" />
										<a-divider style="margin: 4px 0;" />
										<div style="padding: 8px; cursor: pointer; color:var(--orange)"
											@mousedown="e => e.preventDefault()" @click="addNewType">
											<i style="font-size:15px;" class="fe fe-edit-2 mr-2"></i> Add New Task Type
										</div>
									</div>
									<a-select-option v-for="task in taskTypes" :key="task.id" :value="task.id">
										{{ task.name }}
									</a-select-option>
								</a-select>
							</a-form-model-item>
						</a-col>
					</a-row>
					<a-row :gutter="16">
						<a-col :span="12">
							<a-form-model-item label="Task Description" prop="description">
								<a-textarea v-model="newTask.description" placeholder="Task Description" size="large"
									:auto-size="{ minRows: 3, maxRows: 5 }" />
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Due Date" prop="dueDate">
								<a-date-picker size="large" v-model="newTask.dueDate"
									style="width: 100%;"></a-date-picker>
							</a-form-model-item>
						</a-col>
					</a-row>

					<a-row :gutter="16">
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Priority Level" prop="priority"
								:rules="$req('Please select priority level')">
								<div class="dF aC jSB">
									<div @click="newTask.priority = 'high'" class="priority"
										:class="newTask.priority == 'high' ? 'select-high' : 'high-priority'">
										HIGH PRIORITY</div>
									<div @click="newTask.priority = 'medium'" class="priority"
										:class="newTask.priority == 'medium' ? 'select-med' : 'med-priority'">
										MED PRIORITY</div>
									<div @click="newTask.priority = 'low'" class="priority"
										:class="newTask.priority == 'low' ? 'select-low' : 'low-priority'">
										LOW PRIORITY</div>
								</div>
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Assign To" prop="assignTo"
								:rules="$req('Please select any user to assign a task')">
								<a-select v-model="newTask.assignTo" mode="multiple" placeholder="Select a users"
									size="large" :filter-option="$filterOption" show-search>
									<a-select-option v-for="user in users" :key="user.id" :value="user.id">
										{{ user.firstName + ' ' + user.lastName }} ({{ user.email }})
									</a-select-option>
								</a-select>
							</a-form-model-item>
						</a-col>
					</a-row>

					<a-row :gutter="16">
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="What is this task related to?" prop="relatedType"
								:rules="$req('Please select one option below')">
								<a-select v-model="newTask.relatedType" size="large"
									@change="newTask.contact = null; newTask.opportunity = null">
									<a-select-option value="contact">Contact</a-select-option>
									<a-select-option value="opportunity">Opportunity</a-select-option>
								</a-select>
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<template v-if="newTask.relatedType === 'contact'">
								<div v-if="leadTask == ''">
									<a-form-model-item label="Primary Contact" prop="contact"
										:rules="$req('Please enter the contact name')">
										<a-select v-model="newTask.contact" show-search placeholder="Type to search..."
											size="large" @search="searchContacts" :filter-option="$filterOption"
											allowClear>
											<a-spin v-if="searching" slot="notFoundContent" class="dF aC jC" />
											<a-select-option v-for="contact in contactList" :key="contact.value"
												:value="contact.value">
												{{ contact.label }}
											</a-select-option>
										</a-select>
									</a-form-model-item>
								</div>
								<div v-else :key="defaultName">
									<a-form-model-item label="Primary Contact">
										<a-input size="large" :default-value="defaultName" disabled />
									</a-form-model-item>
								</div>
							</template>
							<template v-else>
								<div v-if="opportunityTask == ''">
									<a-form-model-item label="Related To" prop="opportunity"
										:rules="$req('Please enter the opportunity name')">
										<a-select v-model="newTask.opportunity" show-search
											placeholder="Type to search..." size="large" :filter-option="$filterOption"
											allowClear>
											<a-select-option v-for="opp in opportunities" :key="opp.id" :value="opp.id">
												{{ opp.name }}
											</a-select-option>
										</a-select>
									</a-form-model-item>
								</div>
								<div v-else :key="defaultName">
									<a-form-model-item label="Related To">
										<a-input size="large" :default-value="defaultName" disabled />
									</a-form-model-item>
								</div>
							</template>
						</a-col>
					</a-row>
					<!-- Current removed attachment section with v-if="false" condition. We will remove in completely in future if not required -->
					<a-row :gutter="16" v-if="false">
						<a-divider orientation="left">
							<div class="text-md">
								<a-icon class="mr-2" type="paper-clip" /> {{ newTask.files?.length || 0 }}
								{{ newTask.files?.length > 1 ? 'attachments' : 'attachment' }}
							</div>
						</a-divider>
						<div>
							<a-button @click="uploadFiles">
								<a-icon type="upload" /> Upload
							</a-button>
						</div>
						<div v-if="newTask.files?.length" class="mt-3"
							style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr)); gap:2em;">
							<div v-for="(file, fileI) in newTask.files" :key="fileI" class="dF aC jSB px-2 py-1"
								style="background-color: #F7F5F9; border: 1px solid #E6EAF0; border-radius: 5px;">
								<div style="overflow: hidden; white-space: nowrap;">
									<div>{{ file.name }}</div>
									<div style="color: #A3A7AC;">{{ formatFileSize(file.size) }}</div>
								</div>
								<div class="dF aC" style="gap: 10px;">
									<a-button type="primary" ghost @click="downloadFile(file)">
										<a-icon type="download" />
									</a-button>
									<a-button type="danger" ghost @click="newTask.files.splice(fileI, 1)">
										<a-icon type="delete" />
									</a-button>
								</div>
							</div>
						</div>
					</a-row>
				</a-form-model>
				<div class="dF" style="justify-content:flex-end">
					<a-button @click="closeTaskModal" size="large" class="mr-3 cancel-button">CANCEL</a-button>
					<a-button @click="createTask" size="large" type="primary" :loading="loading">CREATE</a-button>
				</div>
			</div>
		</a-modal>
	</div>
</template>
<script>
import moment from 'moment'
import BHLoading from 'bh-mod/components/common/Loading'
import { formatFileSize, debounceFunc } from 'bh-mod/index'
import searchContact from '@/components/common/searchContact'
const FileSaver = require('file-saver')

export default {
	components: {
		BHLoading, searchContact,
		VNodes: {
			functional: true,
			render: (h, ctx) => ctx.props.vnodes,
		},
	},
	data() {
		return {
			loading: false,
			searching: false,
			defaultName: '',
			newTask: {
				title: '',
				description: '',
				priority: '',
				contact: '',
				opportunity: '',
				assignTo: [],
				dueDate: '',
				completed: false,
				type: '',
				relatedType: 'contact',
				files: [],
			},
		}
	},
	watch: {
		duplicateTask: {
			handler(val) {
				if (val.data) {
					let task = val.data
					this.newTask = {
						...this.newTask,
						...task,
						assignTo: task.assignTo.map(x => x.id),
						dueDate: task.dueDate ? moment(task.dueDate) : null,
						contact: task.contact ? task.contact.id : null,
						opportunity: task.opportunity ? task.opportunity.id : null,
					}

					if (task.contact?.id) {
						this.$store.commit('SET_SEARCHED_CONTACT_LIST', [{
							label: task.contact.name ? `${task.contact.name} (${task.contact.email})` : task.contact.email,
							value: task.contact.id
						}])
					}
				}
			},
			deep: true
		},
		visible: {
			handler(val) {
				if (!val) {
					this.$store.commit('CLEAR_LEAD_ACTION')
					this.$store.commit('CLEAR_OPPORTUNITY_ACTION')
				} else {
					this.$store.commit('SET_SEARCHED_CONTACT_LIST', [])
					this.$nextTick(() => {
						this.$refs.newTask?.clearValidate()
					})
				}
			}
		},
		leadTask: {
			async handler(val) {
				if (val && this.contactModal.type == 'Task') {
					this.newTask.relatedType = 'contact'
					this.newTask.contact = val
					let find = this.$store.state.contacts.allContacts[val] || this.$store.state.contacts.externalContacts[val];
					if (find) {
						this.defaultName = `${find.email} (${find.firstName} ${find.lastName})`;
					} else if (this.leadTask) {
						this.loading = true;
						await this.$api.get(`/contacts/:instance/${this.leadTask}`).then( ({data}) => {
                            this.$store.commit('ADD_EXTERNAL_CONTACT', data)
							this.defaultName = `${data.email} (${data.firstName} ${data.lastName})`
                            this.loading = false
                        }).catch(err => {
							this.loading = false
						})
					}
				}
			}
		},
		opportunityTask: {
			async handler(val) {
				if (val && this.contactModal.type == 'Task') {
					this.newTask.relatedType = 'opportunity'
					this.newTask.opportunity = val
					const opportunity = this.$store.state.contacts.opportunities?.find(x => x.id === val);
					if (opportunity) {
						this.defaultName = opportunity.name;
					} else if (this.opportunityTask) {
						this.loading = true;
						await this.$api.get(`/opportunities/:instance/${this.opportunityTask}`).then( ({data}) => {
							this.defaultName = data.name
                            this.loading = false
                        }).catch(err => {
							this.loading = false
						})
					}
				}
			}
		}
	},
	computed: {
		opportunityTask() {
			return this.$store.state.contacts.opportunityAction
		},
		leadTask() {
			return this.$store.state.contacts.leadAction
		},
		visible() {
			return this.$store.state.addNew.visible
		},
		taskTypes() {
			const taskTypes = this.$store.state.contacts?.allSettings?.app?.options?.taskType;
			const list = taskTypes?.length ? taskTypes : [
				{ name: 'To-do', id: 'todo' },
				{ name: 'Call', id: 'call' },
				{ name: 'Follow up', id: 'followup' }
			];

			return list;
		},
		duplicateTask() {
			return this.$store.state.duplicate
		},
		users() {
			return this.$store.state.contacts.users
		},
		contacts() {
			return Object.values(this.$store.state.contacts.allContacts)
		},
		instance() {
			return this.$store.state.instance
		},
		contactModal() {
			return this.$store.state.addNew
		},
		currentUser() {
			return this.$store.state.user
		},
		opportunities() {
			let opportunities = JSON.parse(JSON.stringify(this.$store.state.contacts.opportunities))
			opportunities = opportunities.filter(x => {
				if (x.visibility != 'everyone' && x.visibility != null) {
					let userFound = false
					x.owners.forEach(y => {
						if (y.id == this.currentUser.user.id) userFound = true
					})
					return userFound || this.$p == 50
				} else {
					return true
				}
			})
			return opportunities
		},
		contactList() {
			return this.$store.state.contacts.searchedContactList
		}
	},
	methods: {
		moment, formatFileSize,

		req: msg => ({ required: true, message: msg }),

		async fetchContacts(input) {
			try {
				this.searching = true;
				await this.$store.dispatch('SEARCH_CONTACTS', input)
			} catch (err) {
				this.$toastError(err, 'Error while fetching contact list. Please try again');
			} finally {
				this.searching = false;
			}
		},

		searchContacts: debounceFunc(function (input) {
			if (input.length < 3) return;

			this.fetchContacts(input)
		}, 300),

		addNewType() {
			this.$store.commit('OPEN_LEAD_STATUS', { type: 'taskType' })
		},

		uploadFiles() {
			this.$store.commit('SET_MULTIPLE_IMAGES', true);
			this.$store.commit('MEDIA_SELECT', { callback: this.onFileUpload, type: 'all' });
		},

		onFileUpload(item) {
			this.$set(this.newTask, 'files', [...(this.newTask.files || []), ...item]);
		},

		async downloadFile(file) {
			this.loading = true;
			FileSaver.saveAs(file.url, file.name);
			this.loading = false;
		},

		createTask() {
			this.$refs.newTask.validate(valid => {
				if (valid) {
					this.loading = true

					let sendObj = { ...this.newTask }
					sendObj.dueDate = sendObj.dueDate ? +moment(sendObj.dueDate).format('x') : 0

					if (sendObj.relatedType === 'contact') {
						delete sendObj.opportunity
					} else if (sendObj.relatedType === 'opportunity') {
						delete sendObj.contact
					}

					this.$api.post(`/tasks/:instance`, sendObj).then(({ data }) => {
						this.$message.success('Task created successfully')
						this.$store.commit('ADD_NEW_TASK', data)
						this.closeTaskModal()
					}).catch(err => {
						this.$toastError(err, 'Error while creating new task. Please try again')
					}).finally(() => {
						this.loading = false
					})
				} else {
					return false;
				}
			});
		},
		closeTaskModal() {
			this.newTask = {
				title: '',
				description: '',
				priority: '',
				contact: '',
				opportunity: '',
				assignTo: [],
				dueDate: '',
				completed: false,
				type: '',
				relatedType: 'contact',
				files: [],
			}
			this.$store.commit('CLOSE_ADD_NEW')
		},
	},
}

</script>
<style lang="scss">
.priority-button .ant-radio-button-wrapper {
	border-radius: 4px;
}

.priority-button .ant-radio-button-wrapper-checked {
	border-color: var(--teal) !important;
}
</style>
<style scoped>
.select-high,
.select-med,
.select-low,
.high-priority,
.med-priority,
.low-priority {
	cursor: pointer;
	line-height: 1.6;
	padding: 0 15px;
	border-radius: 4px;
	margin-right: 10px;
	border: 1px solid #9EA0A5;
}

.select-high,
.high-priority:hover {
	background-color: var(--danger);
	color: white;
	border-color: var(--danger);
}

.select-med,
.med-priority:hover {
	background-color: var(--orange);
	color: white;
	border-color: var(--orange);
}

.select-low,
.low-priority:hover {
	background-color: var(--teal);
	color: white;
	border-color: var(--teal);
}

.high-priority {
	color: var(--danger);
	border-color: var(--danger);
}

.med-priority {
	color: var(--orange);
	border-color: var(--orange);
}

.low-priority {
	color: var(--teal);
	border-color: var(--teal);
}
</style>
